@font-face {
  font-family: 'Lato';
  src: url('./font/Lato-Light.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Lato';
  src: url('./font/Lato-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./font/Lato-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  src: url('./font/Lato-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

.chrome-img {
  margin-left: 5px;
  margin-right: 5px;
  height: 30%;
  width: 30%;
  margin-top: 20px;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.05);
}

.lang-img {
  max-width: 20px;
  max-width: 20px;
  margin-right: 10px;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  background-image: url('skyline.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  overflow-y: scroll; 
  
}


body::before {
  content: "";
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.App::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(0px); 
  z-index: -1;
  height: 100vh;
  width: 100vw;
  transition: all 0.4s linear;

}

.blur-active::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(1px); 
  background: rgba(0, 0, 0, 0.15);

  z-index: -1;
}

a {
  text-decoration: none;
}

.logo {
  float: left;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.github-grey {
  z-index: 100000 !important;
  float: left;
  height: 48px;
  width: 48px;
  margin-right: 15px;
  transition: transform 0.15s ease-in;
}


.github-grey:hover {
  transform: scale(1.05);
}


.header {
  z-index: 1000 !important;
  background-color: rgba(17, 17, 17, 0.95);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  width: 100%;
  height: 6%;
  padding-bottom: 0.4%;
  position: fixed;
  top: 0;
  display: flex;
  align-items:center ;
  z-index: 10;
  transition: all 0.3s ease;
}

.header-past{
  z-index: 1000 !important;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  width: 100%;
  height: 70px;
  padding-bottom: 0.4%;
  position: fixed;
  top: 0;
  display: flex;
  align-items:center ;
  z-index: 10;
  transition: all 0.3s ease;
}

.header-hidden {
  transform: translateY(-96.5%);
  height: 6%;
  transition: all 0.3s ease;
}

.progressMainWrapper {
  height: 4.5px;
  position: sticky;
  top: 100px;
  left: 100px;
  z-index: 1;
  width:100%;
}

.progressMainStyle {
  position: absolute; 
  bottom: 0;
  height: 2px;
  background-color: gold;
  box-shadow: 0 0 15px 0 rgba(255, 215, 0, 0.9);
  width:100%;
}

.section-text-mobile {
  display: none;
}


.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  margin-left: 40%;
  margin-right: 1.5%;
  gap: 1%;
}


.name-button {
  font-size: 1.3em;
  font-family: 'lato';
  border: none;
  background-color: transparent;
  color: transparent;
  cursor: pointer;
  margin-left: 1%;
  margin-right: auto;


  background: linear-gradient(280deg, rgb(255, 153, 0) 0%, rgb(255, 242, 0) 35%, rgb(255, 60, 0) 100%);
  -webkit-background-clip: text;
  background-size: 300% 300%;
  animation: gradient 8s ease infinite;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s;
}


.name-button:hover {
  cursor: pointer;
}


.button {
  position: relative; 
  font-size: 1.2em;
  font-family: 'lato';
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.4s;
  color: grey;
}

.button::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%; 
  transform: translateX(-50%); 
  width: 0; 
  height: 0.5px; 
  background-color: grey;
  transition: width 0.3s ease, background-color 0.3s ease; 
}


.button:hover::after {
  background-color: white; 
  width: 100%; 
}

.button:hover {
  color: white;
}

.button.active {
  color: gold;
}

.button.active:hover {
  color: rgb(255, 95, 46);
}

.button.active:hover::after {
  background-color: rgb(255, 95, 46); 
  width: 100%; 

}

.bottom-icons{
  display: none;
}

.name-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  max-height: 100vh;
  margin-bottom: 3%;
  margin-top: 2%;
  position: relative;
  color: white;
  text-align: center;
  opacity: 1;
  overflow: hidden;
}




.section-container {
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-height: 100%;
  margin-top: 20%;
  margin-bottom: 20%;
  position: relative;
  color: white;
  text-align: center;
  opacity: 1;
  overflow: hidden;
}

.course-text-mobile {
  display: none;
}

#skills .section-container {
  align-items: center;
  justify-content: center;
  min-height: 100%; 
  max-height: 100%;
  padding-left: 20% !important;
  padding-right: 20% !important;
  margin-top: 0%;
  margin-bottom: 0%;
  padding-bottom: 10%;
  position: relative;
  color: white;
  text-align: center;
  opacity: 1;
  overflow: hidden;
  margin: 0 auto;
}

.skilsss{
  display: block;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

#skills .section-title{

  margin: none;
}
.skills-container{
  
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  width: 65%;
  margin: 0 auto; /* Add this line */
  margin-top: 20%;
  margin-bottom: 20%;
}



#education .section-container {
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-height: 100%;
  margin-top: 20%;
  margin-bottom: 20%;
  position: relative;
  color: white;
  text-align: center;
  opacity: 1;
  overflow: hidden;
}


#about-me .section-container {
  align-items: center;
  justify-content: center;
  padding: 20%;
  min-height: 100%;
  max-height: 100%;
  margin-top: 35%;
  margin-bottom: 40%;
  position: relative;
  color: white;
  text-align: center;
  opacity: 1;
  overflow: hidden;
}


.item-text {
  font-family: 'lato';
  font-weight: 100;
  font-size: 1.5em;
  text-align: left;
  z-index: 1000 !important;
}

.course-text {
  color: #ADD8E6;
  font-family: 'lato';
  font-weight: 100;
  font-size: 1em;
  text-decoration: underline;
  text-align: left;
  z-index: 1000 !important;
  margin-bottom: 10px;
}


.course-text a {
  color: #ADD8E6;
  font-family: 'lato';
  font-weight: 100;
  font-size: 1.3em;
  text-decoration: underline;
  text-align: left;
  z-index: 1000 !important;
  margin-bottom: 10px;
}


#skills .item-text {
  font-family: 'lato';
  font-weight: 100;
  font-size: 1.43em;
  text-align: left;
  z-index: 1000 !important;
}


.modal-text {
  color: white;
  font-family: 'lato';
  font-weight: 100;
  font-size: 1.15em;
  text-align: left;
}


#about-me .item-container {
  vertical-align: top;
  border: white solid 2px;
  align-items: center;
  justify-items: center;
  border-radius: 1.75rem;
  padding-left: 2%;
  min-width: 70%;
  max-width: 70%;
  min-height: 18em !important;
  max-height: 25em !important;
  padding: 5%;
  margin-top: 5% !important;
  margin-bottom: 5% !important;
  background: transparent;
  z-index: 1 !important;
  opacity: 1;
  position: relative;
  display: flex;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  flex-direction: row;
  margin: auto;
  border: transparent solid 2px;
  transform: scale(0);
  border-radius: 80px;
  animation: sway 8s infinite;
}

@keyframes sway {
  0%, 100%{ transform: rotate(-0.5deg);}
  50%{ transform: rotate(0.5deg); }
}

#about-me .item-container.item-container-visible {
  transform: scale(1);
  box-shadow: none ;


}




#about-me .item-container:before {
  z-index: -5;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-radius: inherit;
}


.item-container {
  vertical-align: top;
  overflow: hidden;
  margin: 1%;
  border-radius: 1.75rem;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  min-width: 40%;
  max-width: 40%;
  min-height: 430px;
  max-height: 430px;
  background: rgba(17, 17, 17, 0.9);
  border: 5px solid rgb(37, 37, 37);
  opacity: 1;
  position: relative;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  transform: scale(0);


  transition: transform 0.5s ease, border 0.2s ease, box-shadow 0.2s ease-in-out, background 0.2s ease;
}




.item-container.item-container-visible {
  transform: scale(1);


}


.item-container.item-container.item-container-visible:hover {
  transform: scale(1.02);
  border: 5px solid rgba(255, 215, 0, 0.8);
  box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0.03);
  

}

#projects .item-container.item-container.item-container-visible:hover {
  cursor:pointer;
}

#experience .item-container.item-container.item-container-visible:hover {
  cursor:pointer;
}


.item-container:before {


  z-index: -5;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg,
      hsla(118deg, 100%, 20%, 0.3) 0%,
      hsla(62deg, 100%, 41%, 0.3) 19%,
      hsla(40deg, 100%, 50%, 0.3) 39%,
      hsla(22deg, 100%, 50%, 0.3) 60%,
      hsla(36deg, 100%, 41%, 0.3) 80%,
      hsla(185deg, 100%, 50%, 0.3) 100%);


  background-size: 400% 400%;
  animation: gradient 6s ease infinite;
  transform: scale(1.01);
  box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-radius: inherit;
}


.item-container:hover:before {
  opacity: 1;
  height: 100%;
  width: 100%;
}




#skills .item-container {
  position: absolute;
  padding: 0px;
  vertical-align: top;
  border-radius: 1.9rem;
  min-width: 11%;
  max-width: 11%;
  min-height: 80px;
  max-height: 80px;
  background: rgb(22, 22, 22);
  box-shadow: 0 0 30px 0px rgba(255, 255, 254, 0.12);
  border: solid rgb(90, 90, 90) 2px;
  z-index: 1 !important;
  opacity: 1;
  position: relative;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

#skills .item-container::before {
  content: '';
  position: fixed;
  top: 0px; /* Adjust the border size */
  left: 0px;
  right: 0px;
  bottom: 0px;
  transform: scale(1.1);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  background-size: 300% 300%; /* Large background size for the gradient animation */
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: 0; /* Hide initially */
  border-radius: inherit; /* Match the container's border-radius */
}

#skills .item-container:hover::before {
  opacity: 1; /* Show the animated border on hover */
}

#skills .item-container:hover {
  transform: scale(1.03);
  border: solid rgb(195, 195, 195) 2px;
  box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0.3);
  animation: glowBorder 1.5s infinite alternate;
}




#education .item-container {
  border: 2px solid white;
  border-radius: 1.75rem;
  padding-left: 2%;
  min-width: 40%;
  max-width: 40%;
  min-height: 500px;
  max-height: 500px;
  margin: 100px;
  background: rgba(17, 17, 17, 0.9);
  z-index: 1;
  opacity: 1;
  position: relative;
  color: white;
  border: grey solid 1px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.2s ease, background 0.2s ease, text-shadow 0.2s ease, border 0.3s ease;
  margin-left: auto;
  margin-right: auto;
}


#education .item-container.item-container.item-container-visible:hover {
  transform: scale(1.02);
  border: 1px solid rgba(162, 0, 255, 0.8);
  box-shadow: 0 0 70px 10px rgba(255, 103, 247, 0.4);
}


#education .item-container:before {
  z-index: -5;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(1.0);
  box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-radius: inherit;
}



.western-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 140px;
}

.education-title {
  font-family: 'lato';
  font-size: 4em;
  vertical-align: top;
}

.western-img {
  -webkit-filter: drop-shadow(2px 2px 0px #ffffff);
  filter: drop-shadow(2px 2px 0px #ffffff);
  position: absolute;
  display: flex;
  left: 100px;
  top: 60px;
  height: calc(39% - 90px);
  width: calc(75% - 50px);
  object-fit: contain;
}



.item-title {
  font-family: 'lato';
  text-align: left;
  padding-left: 2.5px;
  font-size: 1.3rem;
}

#skills .item-title{
  text-align: center;
  
  font-size: 1rem;
  padding-left: 0px;
}


.item-subtitle {
  font-family: 'lato';
  font-weight: 100;
  text-align: left;
  padding-left: 2.5px;
  padding-top: 2%;
  font-size: 0.8em;
}


.projects-title {
  margin-top: 20%;
  margin-bottom: 1%;
  padding-left: 9.5%;
  text-align: left;
  background: linear-gradient(90deg, rgb(255, 153, 0) 0%, rgb(255, 242, 0) 35%, rgb(255, 60, 0) 100%);
  -webkit-background-clip: text;
  background-size: 300% 300%;
  animation: gradient 7s ease infinite;
  -webkit-text-fill-color: transparent;
  font-family: 'lato';
  font-weight: 800;
  font-size: 2.25rem;
}


.section-title {
  margin-bottom: 1%;
  padding-left: 9.5%;
  text-align: left;
  background: linear-gradient(90deg, rgb(255, 153, 0) 0%, rgb(255, 242, 0) 35%, rgb(255, 60, 0) 100%);
  -webkit-background-clip: text;
  background-size: 300% 300%;
  animation: gradient 7s ease infinite;
  -webkit-text-fill-color: transparent;
  font-family: 'lato';
  font-weight: 800;
  font-size: 2.25rem;
  opacity: 0;
  transform: translateX(-350px);
  transition: transform 0.5s ease, opacity 0.1s ease;
}

#skills .section-title{
  padding-left: 6.5rem;
  padding-bottom: 3%;
}


#frameworks .section-title {
  margin-top: 8%;
  margin-bottom: 1%;
  padding-left: 9.5%;
  text-align: left;
  background: linear-gradient(90deg, rgb(255, 153, 0) 0%, rgb(255, 242, 0) 35%, rgb(255, 60, 0) 100%);
  -webkit-background-clip: text;
  background-size: 300% 300%;
  animation: gradient 7s ease infinite;
  -webkit-text-fill-color: transparent;
  font-family: 'lato';
  font-weight: 800;
  font-size: 2.25rem;
  opacity: 0;
  transform: translateX(-350px);
  transition: transform 0.5s ease, opacity 0.1s ease;
}

#education .section-title {
  margin-top: 8%;
  padding-left: 9.5%;
  margin-bottom: -4%;
  text-align: left;
  background: linear-gradient(90deg, rgb(255, 153, 0) 0%, rgb(255, 242, 0) 35%, rgb(255, 60, 0) 100%);
  -webkit-background-clip: text;
  background-size: 300% 300%;
  animation: gradient 7s ease infinite;
  -webkit-text-fill-color: transparent;
  font-family: 'lato';
  font-weight: 800;
  font-size: 2.25rem;
  opacity: 0;
  transform: translateX(-350px);
  transition: transform 0.5s ease, opacity 0.1s ease;
}




.title-visible {
  transform: translateX(0px);
  opacity: 1;
}


#frameworks .title-visible {
  transform: translateX(0px);
  opacity: 1;
}

#education .title-visible {
  transform: translateX(21%);
  opacity: 1;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }


  50% {
    background-position: 100% 50%;
  }


  100% {
    background-position: 0% 50%;
  }
}




.section-text {
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
  font-family: 'Lato';
  font-weight: 100;
  font-size: 1.75em;


  text-shadow: 0px 2px 2px black;
  animation: transform 0.5s ease;


}


.static-txt {
  margin-top: 15%;
  text-align: center;
  z-index: 1;
  font-family: 'lato';
  font-size: 4rem;
  text-shadow: 0px 10px 10px black;
  z-index: 1000000000000;
}


.wrapper {
  align-items: center;


}


.wrapper .dynamic-txts {
  z-index: 1;
  height: 105px;
  line-height: 91px;
  overflow: hidden;
  z-index: 100000000000000000;
}




.dynamic-txts li {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  list-style: none;
  color: gold;
  font-family: 'lato';
  font-size: 4.3rem;
  font-weight: 400;
  position: relative;
  top: 0;
  animation: slide 24s steps(8) infinite;
}


@keyframes slide {
  100% {
    top: -720px;
  }
}


.dynamic-txts li span {
  z-index: 1;
  position: relative;
  animation: typing 3s infinite;
}


@keyframes typing {
  0% {
    opacity: 0;
  }


  60% {
    opacity: 1;
  }


  100% {
    opacity: 0;
  }
}


.headshot {
  height: 400px !important;
  transition: box-shadow 2.5s ease-in-out, border 2.5s ease-in-out;
}

.proj-img {
  border-radius: 30px;
}


.about-me-title {
  text-shadow: 0 0 10px 10px black;
  margin-bottom: 1%;
  padding-right: 4%;
  text-align: center;
  background: linear-gradient(90deg, rgb(255, 153, 0) 0%, rgb(255, 242, 0) 35%, rgb(255, 60, 0) 100%);
  -webkit-background-clip: text;
  background-size: 300% 300%;
  animation: gradient 7s ease infinite;
  -webkit-text-fill-color: transparent;
  font-family: 'lato';
  font-weight: 400;
  font-size: 4rem;
}


.about-me-content {
  display: flex;
  align-items: center;
  padding-left: 12%;
  padding-right: 12%;
  justify-content: center;
  gap: 15px;
}






.hyperlink-imgs {


  transition: all 0.3s ease;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));

  filter: saturate(00%);
}

.hyperlink-git {


  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
  height: 6.5vh;
  filter: saturate(00%) hue-rotate(-10deg);
  transition: all 0.3s ease;

}

.hyperlink-git:hover {
  filter: saturate(100%) ;

}


.hyperlink-imgs:hover {
  filter: saturate(100%);

}


.bottom-icons1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  gap: 20px;
}

.copyright-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


.bottom-icons1 img {
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
}


.modal {
  z-index: 1000 !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
  opacity: 0;
  visibility: hidden;
}


.modal-visible {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease;
}


.modal-hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
}


.no-scroll {
  overflow: hidden;
}


.modal {
  visibility: hidden;
  transition: all 0.25s ease;
}


.modal-fade-in {
  visibility: visible;
  opacity: 1;
}






.modal-content {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  color: white;
  z-index: 1000 !important;
  position: fixed;
  padding: 20px;
  min-height:72%;
  max-height: 70%;
  min-width: 50%;
  max-width: 50%;
  border-radius: 12px;
  background-color: rgb(14, 14, 14);
  border: 3px solid white;
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.modal-content2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1000 !important;
  position: fixed;
  height: 73%;
  width: 51%;
  border-radius: 12px;
  background-color: rgb(14, 14, 14);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  overflow-y: scroll;
  transition: transform 0.3s ease-in-out;
  transition: opacity 1s ease-in-out;
}


.modal-content2::-webkit-scrollbar {
  width: 3px;

}


.modal-content2::-webkit-scrollbar-track {
  background-color: rgb(17, 17, 17);
}


.modal-content2::-webkit-scrollbar-thumb {
  background: rgb(105, 105, 105);
  border-radius: 30px;
}


.modal-content::-webkit-scrollbar-thumb:hover {
  background: white;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.close-modal {
  position: absolute;
  font-family: 'lato';
  font-weight: 800;
  top: 10px;
  right: 10px;
  background: transparent;
  color: black;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 800px;
  border: none;
  padding: 3px 8px;
  cursor: pointer;
  transition: all 0.1s;
  z-index: 5000;

}

.close-modal:hover{
  background-color: rgba(255, 255, 255, 0.8);
}

::-webkit-scrollbar {
  width: 10px;
  z-index: -1000;
  background-color: black;
}


::-webkit-scrollbar-track {
  width: inherit;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-color: rgb(20, 20, 20);
  z-index: -1000;
}


::-webkit-scrollbar-thumb {
  background: rgb(191, 191, 191);
  border-radius: 30px;
  z-index: -1000;

}


::-webkit-scrollbar-thumb:hover {
  background: white;
  z-index: -1000;
}


.copyright-text {
  z-index: 1000 !important;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
  font-family: 'lato';
  font-size: 1rem;
  color: rgb(233, 233, 233);
}


.flowchart-img {
  display: flex;
  width: 90%;
  margin-top: 20px;
  border-radius: 15px;
  margin-right: auto;
  margin-left: auto;
}

.hamburger-menu {
  display: none;
}

.exit-icon{
  display: none;
}

.menu-overlay {
  display: none;
}

.menu-overlay.active {
  display: block;
}

.mobile-buttons {
  display: none;
}

.name {
  display: none;
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {

  #education .section-title {
    transform: translateX(-400px);
    margin-bottom: -80px;
    z-index: 100000000000000;
  }
  

  #education .title-visible {
    transform: translateX(40px);
  }


  .item-text {
    font-size: 1.35em !important;
  }


  .header {
    height: 66px;
  }

  .button-container {
    align-items: center !important;
    margin-right: 5%;
    justify-content: center;
  }


  .name-button {
    visibility: hidden;
    margin-right: -40%;
  }


  .button {
    display: flex;
    justify-content: flex-end;
    align-items: center;


  }


  .name-button {
    font-size: 0%;
  }


  .button {
    font-size: 1em;
  }




  .static-txt {
    padding-top: 100px;
  }


  .section-container {
    margin-top: 15%;
    margin-bottom: 15%;
  }


  .name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
    max-height: 80vh;
    margin-top: 7%;
    margin-bottom: 12em;
  }


  .headshot {
    height: 15em !important;
    width: auto !important;
    border-radius: 30% !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }


  .section-text {
    font-size: 1.12em;
  }


  .item-container {
    min-width: 80% !important;
    max-width: 80% !important;
    min-height: 375px !important;
    max-height: 375px !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  #skills .item-container {
    position: absolute;
    padding: 0px !important;
    vertical-align: top;
    border-radius: 1.9rem;
    min-width: 90px !important;
    max-width: 30px !important;
    min-height: 70px !important;
    max-height: 70px !important;
    background: rgb(22, 22, 22);
    box-shadow: 0 0 30px 0px rgba(255, 255, 254, 0.12);
    box-shadow: 0 0 30px 0px rgba(255, 255, 254, 0.12);
    z-index: 1 !important;
    opacity: 1;
    position: relative;
    color: white;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  #skills .item-title{
    font-size: 0.9rem;
  }
  

  #about-me .item-container {
    display: flex;
    flex-direction: row;
    align-items: flex;
    justify-content: center;
    padding-right: 50px !important;
    min-width: 70% !important;
    max-width: 70% !important;


    margin-top: 10% !important;
    margin-bottom: 20% !important;
    margin-left: auto;
    margin-right: auto;
    background: none;
  }


  #about-me .item-container.item-container-visible {
    transform: scale(1);


  }


  #about-me .item-container.item-container.item-container-visible:hover {
    transform: scale(1);


  }






  #about-me .item-container:before {
    z-index: -5;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: inherit;
  }



  #education .item-container {
    margin-left: auto;
    margin-right: auto;
    min-height: 550px !important;
    max-height: 550px !important;
  }


  .section-title {
    padding-left: 11%;
  }


  .item-title {
    font-size: 1.1rem;
  }


  .item-subtitle {
    font-size: 0.9em;
  }




  .modal-content {
    min-width: 80%;
    max-width: 80%;
  }

  .modal-content2{
    width: 81%;
  }

  .logo,
  .github-grey,
  .headshot {
    height: auto;
    width: auto;
  }

  #education .section-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-icons img {
    max-width: 40px;
    max-height: 40px;
  }


  .logo {
    max-width: 60px;
    max-height: 60px;
  }


  .github-grey {
    max-width: 7vh;
    max-height: 7vh;
  }
}




@media only screen and (max-width: 481px) {
  #about-me .item-container {
    display: none;
  }

  .hyperlink-imgs {


  transition: all 0.3s ease;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));

  filter: saturate(100%);
}

  .progressMainWrapper{
    left: -4px;
    width: 70%;
    height: 3px;

  }

  .progressMainStyle{
    left: -4px;
    width: 70%;
    height: 3px;

  }

  .modal-content2{
    width: 80%;
  }

  .button.active {
    transform: scale(1.04);
    color: gold;
  }

  .button:hover {
    transform: scale(1.04);
    color: white;
  }

  .lang-container{
    width: 100%;
  }

  .skills-container{
    width: 100%;
    margin-top: 70px;
  }

  #frameworks .lang-container{
    margin-top: 15%;
  }

  .lang-container-visible{
    transform: scale(1);
    width: 100%;
  }

  .section-text {
    display: none;
  }

  .course-text {
    display: none;
  }


  .frameworks-title {
    margin-top: 0px;
    padding-left: 18%;
    font-size: 1.5rem !important;
  }


  .lang-img {
    max-width: 30px;
    max-width: 30px;
    margin-right: 10px;
  }


  .modal-text {
    font-family: 'lato';
    font-weight: 100;
    font-size: 0.85em;
    text-align: left;
  }


  .modal {
    min-width: 95% !important;
  }


  .modal-content {
    min-width: 80% !important;
  }


  body {
    background-image: url('skyline.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: black;
    height: 120%;
    z-index: -1;
  }


  body::before {
    background-image: url('skyline.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    z-index: -1;
  }

  #skills .item-title {
    text-shadow: 1px 1px 2px black;
  }


  .headshot {
    display: none;
  }


  .headshot-modal {
    height: 35vh;
    border-radius: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }


  .static-txt {
    display: none;
  }


  #skills .section-title{
    margin-right: 50px !important;
  }

  .wrapper {
    display: none !important;
  }

  .logo {
    height: 40px !important;
    width: 40px ;
  }


  .section-title {
    padding-left: 18%;
    font-size: 1.5rem !important;
  }


  .section-text-mobile {
    font-family: 'lato';
    font-size: 1.2em;
    color: white;
    align-items: center;
    min-width: 78% !important;
    max-width: 78% !important;
    min-height: 50% !important;
    max-height: 50% !important;
    margin-left: auto;
    margin-right: auto;
    font-weight: 100;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
    background-color: rgba(17, 17, 17, 0.9);
    border: 5px solid rgb(37, 37, 37);
    padding-top: 3%;
    padding-bottom: 10%;
    border-radius: 20px;
    display: block;
    margin-top: 10%;
    margin-bottom: 10%;
  }


  .item-text {
    display: none;
  }


  .name-container {
    display: none;
  }

 

  #skills .item-container:before {
    background-image: none;
  }




  .skills-section .item-container {
    flex-basis: calc(33% - 10px);
  }


  .header {
    display: flex;
    justify-content: left;
    align-items: left;
    background-image: none;
    height: 30px;
    background-color: rgba(17, 17, 17, 1);
    padding: 10px 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.6);
  }

  .header-hidden {
    transform: none !important;
  }

  .mobile-buttons {
    margin-top: 13%;
    display: flex;
    flex-direction: column;
    
  }

  .hamburger-menu {
    background-color: transparent;
    border: none;
    display: block;
    position: absolute;
    right: 20px;
    top: 17px;
    margin-right: 30px;
    height: 15px;
  }

  .exit-icon {
    background-color: transparent;
    border: none;
    display: block;
    position: absolute;
    right: 10px;
    top: 15px;
    height: 15px;
  }

  .header .logo,
  .header .button-container,
  .header .name-button {
    display: none;
  }

  .name {
    flex-grow: 1;
    border: none;
    font-size: 1.1em;
    position: absolute;
    left: 2%;
    display: inline;
    font-family: lato;
    background: linear-gradient(280deg, rgb(255, 153, 0) 0%, rgb(255, 242, 0) 35%, rgb(255, 60, 0) 100%);
    -webkit-background-clip: text;
    background-size: 300% 300%;
    animation: gradient 8s ease infinite;
    -webkit-text-fill-color: transparent;
  }

  #mobile-menu .button {
    margin-bottom: 40px;
  }

  .menu-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 48%;
    width: 53%;
    height: 100%;
    background-color: rgba(17, 17, 17, 1);
    z-index: 2;
    transform: translateX(0px);
    transition: all 0.4s ease;
  }

  .menu-overlay.active {
    transform: translateX(250px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;

  }

  ::-webkit-scrollbar {
    display: none;
  }

  .section-container {
    margin-top: 40%;
    margin-bottom: 40%;
  }

  .item-title {
    font-size: 0.9rem;
  }


  .item-subtitle {
    font-size: 0.7rem;
  }


  .item-container {
    min-width: 78% !important;
    min-height: 120px !important;
    max-height: 120px !important;
    padding-left: 5%;
    padding-right: 2%;
    margin: 5%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
  }

  #skills .item-container {
    min-height: 25% !important;
    max-height: 25% !important;
    min-width: 35% !important;
    max-width: 35% !important;

  }


  #education .item-container {
    min-width: 78% !important;
    min-height: 120px !important;
    max-height: 120px !important;
    padding-left: 5%;
    padding-right: 2%;
    margin: 5%;
    border: none;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
    margin-top: 20%;
  }

  #education .item-container.item-container.item-container-visible:hover {
    transform: scale(1.02);
    border: none;
    box-shadow: 0 0 30px 10px rgba(255, 103, 247, 0.3);
  }

  .course-text-mobile {

    margin-bottom: -10px;
    font-family: 'lato';
    display: block;
    margin-right: 10px;
    font-size: 1em;
    font-weight: 100 !important;
  }

  .western-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50px;
  }

  .education-title {
    font-family: 'lato';
    font-size: 4em;
    vertical-align: top;
  }

  #education .section-title {
    transform: translateX(-350px);
  }

  #education .title-visible {
    transform: translateX(20px);
    margin-bottom: 7px;
  }

  .western-img {
    -webkit-filter: drop-shadow(1px 1px 0px #ffffff);
    filter: drop-shadow(1px 1px 0px #ffffff);
    position: absolute;
    top: 10px;
    left: 80px;
    height: 50%;
    width: 50%;
    object-fit: contain;
  }

  .flowchart-img {
    display: flex;
    width: 100%;
    height: 35%;
    margin-top: 20px;
    border-radius: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .about-me-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: center;
  }

  .about-me-title {
    font-weight: 100;
    font-size: 2rem;
    text-align: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .headshot-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5vh;
  }

  .bottom-icons1{
    display: none;
  }

  .bottom-icons  {
    left: 47%;
    position: relative;
    z-index: 0 !important;
    max-width: 5vh;
    max-height: 3.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    display: flex;
    flex-shrink: 0; 
    gap: 15px;
  }


  .bottom-icons img {
    position: relative;
    z-index: 5 !important;
    max-width: 5vh;
    max-height: 3.1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }

  .copyright-text {
    position: relative;
    font-size: 0.6rem;
    z-index: 5 !important;
  }

  #skills .section-title{
    padding-left: 3rem;
    padding-bottom: 3%;
  }



}